import Button from '@landfolk/shared/components/Button'
import { TxComponent } from '@landfolk/styling/types/landstyle'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { ComponentPropsWithoutRef, ReactNode, useEffect, useState } from 'react'

export default function Layout({ children }: { children: ReactNode }) {
  const [isOpen, setOpen] = useState(false)
  const router = useRouter()

  // Do not make header sticky on calendar example
  const isNotSticky = router.asPath.split('?')[0] === '/components/Calendar'

  useEffect(() => setOpen(false), [router.asPath])

  return (
    <>
      <header
        tx={[
          tx`sticky left-0 right-0 top-0 z-[90] flex h-3 items-center justify-between bg-white p-3 text-f6 shadow-md lg:hidden`,
          isNotSticky && tx`relative`,
        ]}
      >
        <Logo />

        <Button
          size="sm"
          tx={[
            tx`lg:hidden`,
            isOpen &&
              tx`border-february bg-february text-white hover:(border-february-muted bg-february-muted)`,
          ]}
          onClick={() => setOpen(!isOpen)}
        >
          Menu
        </Button>
      </header>

      <div tx="flex flex-row">
        <aside
          tx={[
            tx`left-0 bg-white lg:(sticky top-0 h-screen w-5 overflow-y-auto p-4 shadow-md) nl:(fixed z-[99] w-screen transform overflow-y-auto p-3 transition-transform)`,
            isOpen ? tx`nl:translate-y-0` : tx`nl:(top-0 -translate-y-full)`,
          ]}
        >
          <div tx="lg:(sticky -top-4 -mx-4 -mt-4 bg-white px-4 pb-3 pt-4) nl:hidden">
            <Logo mobile tx="mb-0" />
          </div>

          <nav>
            <Examples entries={basics} title="Basics" folder="basics" />
            <Examples
              entries={components}
              title="Components"
              folder="components"
            />
          </nav>
        </aside>

        <div tx={[tx`w-full p-3 lg:p-5`, isOpen && tx`overflow-hidden`]}>
          {children}
        </div>
      </div>
    </>
  )
}

const Examples = ({
  title,
  folder,
  entries,
}: {
  title: string
  folder: string
  entries: Set<string>
}) => (
  <>
    <h4 tx="mb-2 mt-4 border-b border-february-secundo pb-3 text-f6 text-february-muted">
      {title}
    </h4>
    {[...entries].map((name) => (
      <NavLink key={name} href={`/${folder}/${name}`}>
        {name}
      </NavLink>
    ))}
  </>
)

const NavLink = ({
  children,
  href,
  className,
  ...rest
}: ComponentPropsWithoutRef<'a'> & { href: string }) => {
  const router = useRouter()
  const isActive = href === router.asPath

  return (
    <Link
      href={href}
      className={className}
      tx={[
        tx`-mx-4 block px-4 py-2 text-f6`,
        isActive && tx`font-bold no-underline`,
      ]}
      {...rest}
    >
      {children}
    </Link>
  )
}

const Logo = ({
  mobile,
  className,
  ...restProps
}: { mobile?: boolean } & TxComponent) => (
  <Link
    href="/"
    className={className}
    tx={[
      tx`relative z-10 flex flex-row items-center font-bold no-underline`,
      mobile && tx`nl:hidden`,
    ]}
    {...restProps}
  >
    {/* eslint-disable-next-line @next/next/no-img-element */}
    <img
      tx="mr-2"
      width="24"
      height="24"
      src="/favicon.svg"
      alt="Landfolk Logo"
    />
    Design System
  </Link>
)

const components = new Set(process.env.components?.split(',') ?? [])
const basics = new Set(process.env.basics?.split(',') ?? [])
