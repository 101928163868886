import 'focus-visible'

import Script from 'next/script'

// Some Intl polyfills doesn't support locales with a dialect.
// Remove the `.min.` part of the url and check if some of them
// isn't correctly detected when adding a new polyfill.
const getPolyfillUrl = (locale: string) => {
  const [language = locale, dialect] = locale.split('-')

  if (dialect) {
    locale = `${language}-${dialect.toUpperCase()}`
  }

  return (
    'https://cdnjs.cloudflare.com/polyfill/v3/polyfill.min.js?features=' +
    [
      `Intl.DisplayNames.~locale.${locale}`,
      `Intl.DateTimeFormat.~locale.${locale}`,
      `Intl.DateTimeFormat.~timeZone.golden`,
      `Intl.Locale`,
      `Intl.getCanonicalLocales`,
      `Intl.NumberFormat.~locale.${locale}`,
      `Intl.ListFormat.~locale.${locale}`,
      `Intl.PluralRules.~locale.${language}`,
      `Intl.RelativeTimeFormat.~locale.${locale}`,
      `String.prototype.matchAll`,
      `ResizeObserver`,
      `WebAnimations`,
    ].join(',')
  )
}

export default function Polyfills({ locale = 'en' }: { locale: string }) {
  return <Script src={getPolyfillUrl(locale)} strategy="beforeInteractive" />
}
