import { DM_Mono, Work_Sans } from 'next/font/google'
import localFont from 'next/font/local'

const fontSans = Work_Sans({
  weight: ['400', '500', '600', '900'],
  style: ['normal', 'italic'],
  subsets: ['latin'],
})

const fontMono = DM_Mono({
  weight: ['400'],
  style: ['normal'],
  subsets: ['latin'],
  // monospace is rarely used, so we do not preload this
  preload: false,
})

const fontSerif = localFont({
  src: [
    {
      path: './files/Study/webfont-subset/Study_Regular.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: './files/Study/webfont-subset/Study_Italic.woff2',
      weight: '400',
      style: 'italic',
    },
  ],
  fallback: ['Georgia'],
})

const fontHandwritten = localFont({
  src: [
    {
      path: './files/0bd36706-dfd2-4884-949b-f084cb82f797.woff2',
      weight: '400',
      style: 'normal',
    },
  ],
  adjustFontFallback: 'Arial',
  // Handwriting is rarely used, so we do not preload this
  preload: false,
})

/**
 * Loads shared fonts via next/font and sets up CSS variables for all font families
 * so tailwind theme can reference these
 */
export function SharedFonts() {
  return (
    <style jsx global>{`
      :root {
        --font-sans: ${fontSans.style.fontFamily};
        --font-serif: ${fontSerif.style.fontFamily};
        --font-mono: ${fontMono.style.fontFamily};
        --font-handwritten: ${fontHandwritten.style.fontFamily};
      }
    `}</style>
  )
}
