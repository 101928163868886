import { useId } from 'react'

import { SVGIconProps } from '../types/svgIconProps'

type SpinnerProps = {
  thickness?: number
  className?: string
  compact?: boolean
} & ({ compact?: boolean; size?: never } | { compact?: never; size?: number }) &
  SVGIconProps

export default function Spinner({
  thickness = 4,
  className,
  compact = false,
  size = 24,
  ...restProps
}: SpinnerProps) {
  const id = useId()

  const diameter = compact ? 18 : size
  const radius = diameter / 2

  return (
    <svg
      tx="animate-spin"
      className={className}
      viewBox={`0 0 ${diameter} ${diameter}`}
      width={diameter}
      height={diameter}
      data-testid="spinner"
      {...restProps}
    >
      <defs>
        <mask id={`${id}-spinnerMask`}>
          <rect width="100%" height="100%" fill="white" />
          <circle cx={radius} cy={radius} r={radius - thickness} fill="black" />
          <polygon
            points={`${radius} 0, ${radius} ${radius}, 0 ${diameter}, ${diameter} ${diameter}, ${diameter} 0, ${radius} 0`}
            fill="black"
          />
        </mask>
      </defs>
      <circle
        cx={radius}
        cy={radius}
        r={radius}
        fill="currentColor"
        mask={`url(#${id}-spinnerMask)`}
      />
    </svg>
  )
}

export const PageSpinner = () => (
  <div tx="fixed inset-0 z-navigation grid place-content-center text-february-tertian">
    <Spinner />
  </div>
)
