import { TxComponent } from '@landfolk/styling/types/landstyle'
import {
  ComponentPropsWithoutRef,
  ElementType,
  forwardRef,
  ReactNode,
  useEffect,
  useState,
} from 'react'

import { shadows } from '../../tokens/base/shadows'
import Checkmark from '../icons/Checkmark.svg'
import { PolymorphicRef } from '../types/polymorphic'
import { variant } from '../utils/styled'
import Spinner from './Spinner'

const DEFAULT_COMPONENT = 'button' as const

export const KINDS = ['primary', 'secondary', 'club'] as const
export const STATES = ['loading', 'success', 'error'] as const
export const TIMEOUT = 1000

export type ButtonState = (typeof STATES)[number] | false

type BaseButtonProps = {
  kind?: (typeof KINDS)[number]
  state?: ButtonState
  icon?: ReactNode
  light?: boolean
  size?: 'sm' | 'md' | 'lg'
  danger?: boolean
  submit?: boolean
  stateTimeout?: number
  shadow?: keyof typeof shadows
}

export type ButtonProps<C extends ElementType = 'button'> = BaseButtonProps & {
  as?: C
} & Omit<ComponentPropsWithoutRef<C>, keyof BaseButtonProps | 'as'> &
  TxComponent

const Button = forwardRef(
  <C extends ElementType = 'button'>(
    {
      as,
      kind = 'primary',
      state,
      icon,
      light,
      danger,
      size = 'lg',
      stateTimeout = TIMEOUT,
      children,
      disabled,
      className,
      style,
      shadow,
      ...rest
    }: ButtonProps<C>,
    forwardedRef: PolymorphicRef<C>,
  ) => {
    const Component = as || DEFAULT_COMPONENT
    const [localState, setLocalState] = useState(state)

    useEffect(() => {
      setLocalState(state)
    }, [state])

    useEffect(() => {
      let timeout: NodeJS.Timeout

      if (localState === 'success' || localState === 'error') {
        timeout = setTimeout(() => setLocalState(undefined), stateTimeout)
      }

      return () => clearTimeout(timeout)
    }, [localState, stateTimeout])

    let iconChildren = icon ?? undefined

    switch (localState) {
      case 'loading':
        iconChildren = (
          <Icon
            content={Spinner}
            tx={[getIconVariantStyles({ disabled, kind })]}
          />
        )
        break

      case 'success':
        iconChildren = (
          <Icon
            content={Checkmark}
            tx={[getIconVariantStyles({ disabled, kind })]}
          />
        )
        break
    }

    return (
      <Component
        ref={forwardedRef}
        className={className}
        style={style}
        tx={[
          // Base styles
          tx`
            relative
            whitespace-nowrap font-bold no-underline
            rounded-full border
            transition duration-100 ease-in
            focus:outline-offset-3
            disabled:cursor-default
            [-webkit-tap-highlight-color:transparent]
          `,

          // Size variants
          variant(
            size === 'lg' &&
              tx`min-h-[3rem] px-3.5 py-2.5 leading-tight active:scale-[99%] lg:(min-h-[3.5rem] px-4 py-3)`,
            size === 'md' &&
              tx`min-h-[3rem] px-3.5 py-2.5 leading-tight active:scale-[99%]`,
            size === 'sm' &&
              tx`min-h-[2rem] px-3 py-2 text-f7 leading-none active:scale-[97%]`,
          ),

          // Color/kind variants
          getVariantStyles({ disabled, light, danger, kind }),

          // Error animation
          localState === 'error' && tx`animate-shake`,
        ]}
        disabled={disabled || Boolean(localState)}
        {...rest}
      >
        {iconChildren}
        <span
          tx={[
            tx`transition-opacity duration-300`,
            (localState === 'loading' || localState === 'success') &&
              tx`opacity-0`,
          ]}
        >
          {children}
        </span>

        {shadow && (
          // Fixes bug where "shadow-*" classes override the inset shadow on the button
          <span
            tx={[
              `shadow-${shadow}`,
              tx`pointer-events-none absolute inset-[-1px] isolate z-[0] h-[calc(100%+2px)] w-[calc(100%+2px)] rounded-[inherit]`,
            ]}
          />
        )}
      </Component>
    )
  },
)

Button.displayName = 'Button'

export default Button

type IconContent = typeof Spinner | typeof Checkmark

const Icon = ({
  content: Content,
  className,
  ...restProps
}: {
  content: IconContent
} & TxComponent) => (
  <span tx="absolute left-0 top-0 inline-flex h-full w-full items-center justify-center">
    <Content className={className} tx="h-[2ch]" {...restProps} />
  </span>
)

function getVariantStyles({
  disabled,
  light,
  danger,
  kind,
}: Pick<ButtonProps, 'kind' | 'light' | 'danger'> & { disabled?: boolean }) {
  // Disabled button
  if (disabled) {
    return variant(
      tx`border-february-secundo bg-february-primo text-february-muted/50`,
      light &&
        tx`border-february-muted bg-february-muted text-february-tertian`,
    )
  }

  // Danger/error button
  if (danger) {
    return variant(
      tx`border-error bg-error text-white can-hover:hover:(brightness-90 contrast-[1.1])`,
      kind === 'secondary' &&
        tx`border-error text-february can-hover:hover:bg-error/10`,
      kind === 'secondary' &&
        light &&
        tx`border-error text-white can-hover:hover:bg-error/10`,
    )
  }

  // Club button
  if (kind === 'club') {
    return variant(
      tx`
        border-honey-700 bg-honey-700 text-daylight
        can-hover:hover:contrast-150
        [box-shadow:inset_-0.5em_-1em_2em_rgba(2,32,33,0.2)]
        active:[box-shadow:inset_0.5em_1em_2em_rgba(2,32,33,0.2)]
      `,
      light &&
        tx`
          border-daylight bg-daylight text-honey-800
          can-hover:hover:brightness-90
          [box-shadow:inset_-0.5em_-1em_2em_rgba(2,32,33,0.07)]
          active:[box-shadow:inset_0.5em_1em_2em_rgba(2,32,33,0.1)]
        `,
    )
  }

  // Secondary button
  if (kind === 'secondary') {
    return variant(
      tx`
        border-february-muted bg-transparent text-february
        can-hover:hover:(bg-february/5 text-february)
        active:(border-february bg-february-secundo)
      `,
      light &&
        tx`
          border-february-secundo bg-transparent text-white
          can-hover:hover:(bg-white/10 !text-white)
          active:(border-february-tertian bg-opacity-5)
        `,
    )
  }

  // Primary button
  return variant(
    tx`
      border-spring bg-spring text-white can-hover:hover:contrast-150
      [box-shadow:inset_-0.5em_-1em_2em_rgba(2,32,33,0.2)]
      active:[box-shadow:inset_0.5em_1em_2em_rgba(2,32,33,0.2)]
    `,
    light &&
      tx`
        border-white bg-white text-february can-hover:hover:brightness-90
        [box-shadow:inset_-0.5em_-1em_2em_rgba(2,32,33,0.07)]
        active:[box-shadow:inset_0.5em_1em_2em_rgba(2,32,33,0.1)]
      `,
  )
}

function getIconVariantStyles({
  disabled,
  kind,
}: Pick<ButtonProps, 'kind'> & { disabled?: boolean }) {
  return variant(
    tx`text-white`,
    kind === 'club' && tx`text-honey-800`,
    kind === 'secondary' && tx`text-february`,
    disabled && tx`text-february`,
  )
}
