import { TailwindClasses, TxList } from '@landfolk/styling/types/landstyle'
import {
  ComponentPropsWithoutRef,
  createElement,
  DetailedHTMLProps,
  forwardRef,
} from 'react'

import { $ANY, $NULL } from './types'

type StyleFunction<P> = (props: P) => TailwindClasses | TxList
type StyleInput<P> = TailwindClasses | TxList | StyleFunction<P>

/**
 * Creates a styled component with Tailwind classes and ref forwarding support
 * @template T - HTML element type (e.g., 'div', 'span')
 * @template P - Custom props type (optional)
 * @param {T} element - HTML element to style
 * @param {StyleInput<P>} classes - Tailwind classes, array of classes, or style function
 * @example
 * // Single class string
 * const Box = styled('div', tx`bg-february-tertian p-4`)
 *
 * // Multiple classes in an array
 * const Box = styled('div', [tx`bg-february-tertian`, tx`p-4`])
 *
 * // Props-based styling
 * const Button = styled('button', ({ size = 'sm' }: { size?: 'sm' | 'md' }) => [
 *   tx`bg-spring text-white rounded`,
 *   size === 'sm' && tx`p-2 h-1`,
 *   size === 'md' && tx`p-3 h-2.5`,
 * ])
 * // Usage
 * <Button>I'm a small button</Button>
 * <Button size="md">I'm a medium button</Button>
 *
 * // Static conditional classes (no runtime conditionals)
 * const Card = styled('div', [
 *   tx`rounded-lg`,
 *   __IS_DEV__ && tx`bg-summer text-white`,
 * ])
 *
 * // Supports ref forwarding
 * const Box = styled('div', tx`bg-february-tertian p-4`)
 * // Usage
 * const boxRef = useRef<HTMLDivElement>(null)
 * <Box ref={boxRef}>Content</Box>
 */
export function styled<
  T extends keyof JSX.IntrinsicElements,
  P extends object = Record<string, unknown>,
>(element: T, classes: StyleInput<P>) {
  const Component = forwardRef<
    JSX.IntrinsicElements[T] extends DetailedHTMLProps<$ANY, infer R>
      ? R
      : never,
    P & ComponentPropsWithoutRef<T>
  >(({ className = '', children, ...props }, ref) => {
    // First resolve tailwind classes with all props, custom and DOM props alike
    const resolvedClasses =
      typeof classes === 'function' ? classes(props as P) : classes

    // Filter to only valid DOM props using our helper. This ensures we avoid
    // passing invalid custom props to the DOM.
    const domProps = filterDOMProps(props)

    return createElement(
      element,
      {
        ref,
        className: [
          className,
          Array.isArray(resolvedClasses)
            ? resolvedClasses.join(' ')
            : resolvedClasses,
        ].join(' '),
        ...domProps,
      },
      children,
    )
  })

  Component.displayName = `styled.${element}`
  return Component
}

/**
 * Common HTML attributes that should be preserved
 *
 * TODO: Consider using a more robust library like `@emotion/is-prop-valid` for this? Or maybe `prop-types`?
 */
const COMMON_PROPS = new Set([
  // Form attributes
  'type',
  'value',
  'defaultValue',
  'checked',
  'defaultChecked',
  'disabled',
  'required',
  'readOnly',
  'placeholder',
  'name',

  // Interactive attributes
  'tabIndex',
  'draggable',
  'contentEditable',
  'hidden',

  // Accessibility
  'role',
  'id',
  'title',

  // Layout and styling
  'style',
  'className',

  // Media attributes
  'src',
  'alt',
  'href',
  'target',

  // Other common attributes
  'rel',
  'download',
])

/**
 * Filters props to only include valid DOM properties
 */
function filterDOMProps(
  props: Record<string, unknown>,
): Record<string, unknown> {
  const domProps: Record<string, unknown> = {}

  Object.entries(props).forEach(([key, value]) => {
    if (
      key.startsWith('on') || // Event handlers
      key.startsWith('data-') || // Data attributes
      key.startsWith('aria-') || // ARIA attributes
      COMMON_PROPS.has(key) // Common HTML attributes
    ) {
      domProps[key] = value
    }
  })

  return domProps
}

/**
 * Returns a single Tailwind classes string based on conditions, with later conditions overriding earlier ones.
 * @example
 * // With default
 * tx={[variant(
 *   tx`text-february-muted`,
 *   isActive && tx`text-february`
 * )]}
 *
 * // Multiple conditions
 * tx={[variant(
 *   tx`bg-february-muted`,
 *   isActive && tx`bg-february`,
 *   isHighlighted && tx`bg-spring`
 * )]}
 *
 * // With other styling
 * tx={[
 *   tx`bg-february-muted`,
 *   variant(
 *     tx`text-february-muted`,
 *     isActive && tx`text-february`
 *   )
 * ]}
 */
export function variant(
  ...classes: (TailwindClasses | false | $NULL | '' | 0 | undefined)[]
): TailwindClasses {
  const lastTruthy = classes.filter(Boolean).pop()
  return lastTruthy || ('' as TailwindClasses)
}
