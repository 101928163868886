import 'swiper/css'
import 'swiper/css/pagination'
import '@landfolk/styling/global.css'
import '../global.css'

import { SharedFonts } from '@landfolk/shared/components/Fonts/Shared'
import Polyfills from '@landfolk/shared/components/Polyfills'
import { useScrollbarSize } from '@landfolk/shared/utils/useScrollbarSize'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import PlausibleProvider from 'next-plausible'
import { IntlProvider } from 'react-intl'

import Layout from '@/components/Layout'

const LOCALE = 'en-GB'

if (__IS_DEV__) {
  // Add utility for checking for conflicting class names in dev mode.
  require('@landfolk/styling/utils/checkConflicts')
}

export default function App({ Component, pageProps }: AppProps) {
  const scrollbarSize = useScrollbarSize()

  return (
    <>
      <Polyfills locale={LOCALE} />

      <Head>
        <title>Landfolk Design System</title>
        <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
      </Head>

      <SharedFonts />
      <style jsx global>{`
        :root {
          --scrollbar-size: ${scrollbarSize ?? 0}px;
          --ui-bottom-safe-inset: calc(env(safe-area-inset-bottom, 0) / 2);
        }
      `}</style>

      <IntlProvider locale={LOCALE} onError={() => ({})}>
        <PlausibleProvider
          domain="design.landfolk.com"
          enabled={__IS_PRODUCTION__}
        >
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </PlausibleProvider>
      </IntlProvider>
    </>
  )
}
